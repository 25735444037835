import React from "react"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import me from '../images/chance.jpg'
import puente from '../images/projects/puente-bg.jpg'
import asc from '../images/projects/adrian-steel-bg.jpg'
import aw from '../images/projects/arctic-wolf-bg.jpg'

// Component Imports
import Layout from "../layout"
import ResumeCTA from '../components/resume-cta'

const Home = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  return (
    <Layout>


      <section class="about">
        <div class="sw">
          <div class="content">
            <div className="left">
              <h1>I'm Chance, a UX Designer and Frontend Developer based out of Ann Arbor, Michigan.</h1>
              <p>I take an agile, data-driven approach to my work, iterating and improving digital products to create holistic, user-friendly interfaces and applications. I enjoy the challenge of setting goals and designing focused solutions that improve the usability of the project at hand.</p>
            </div>
            <div className="right" style={{ backgroundImage: `url(${me})` }}>
              <img src={me} alt="" />
            </div>
          </div>
        </div>
      </section>


      <section class="what-i-do">
        <div class="sw">
          <div class="content">
            <h2>What I Do</h2>
            <p>In designing digital products, I listen to the user, implementing UX research methods such as surveys and user interviews into my design process. In development, I keep the user at the center of the process, iterating on the product until it fits their needs. I draw on my experience in group projects and collegiate athletics to be a great member of any team.<br /><br />Take a look at some of my favorite projects below!</p>
            <div className="specialties">
              <a href="./projects/puente" class="cta cta--project" style={{ backgroundImage: `url(${puente})` }}>Puente</a>
              <a href="./projects/adrian-steel" class="cta cta--project" style={{ backgroundImage: `url(${asc})` }}>Adrian Steel</a>
              <a href="./projects/arctic-wolf" class="cta cta--project" style={{ backgroundImage: `url(${aw})` }}>Arctic Wolf</a>
              {/* <a href="./projects/safe-send" class="cta cta--project" style={{ backgroundImage: `url(${safesend})` }}>SafeSend</a> */}
            </div>
          </div>
        </div>
      </section>


      <ResumeCTA />


    </Layout>
  )
}

export default Home
